import logo from './logo.svg';
import './App.css';
import Hero from './Components/Hero/Hero';
import About from './Components/About/About';
import Features from './Components/Features/Features';
import Tokenomics from './Components/Tokenomics/Tokenomics';
import Timeline from './Components/Timeline/Timeline';

function App() {
  return (
    <div className='max-w-[100vw] overflow-clip'>
     <Hero/>
     <About/>
     <Features/>
     <Tokenomics/>
     <Timeline/>
     <div className='w-[100vw] bg-white'>
        <p className='text-center text-[#000000] py-4'>All rights reserved @ 2024</p>
     </div>
    </div>
  );
}

export default App;
