import React from 'react';
import Lottie from 'react-lottie';
import * as animationData from '../../Assets/l1.json'
import Button from '../Button/Button';


const Hero = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <div className="relative bg-red-400 h-[100vh] w-[100vw]  overflow-hidden">
            {/* Background Video */}
            <video
                autoPlay
                loop
                muted
                playsInline
                className="absolute top-0 left-0 w-[100vw] h-[100vh] object-cover"
            >
                <source src={require('../../Assets/bg.mp4')} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            {/* Optional overlay for darker effect */}
            <div className="absolute inset-0 bg-black opacity-50"></div>

            {/* Centered text */}
            <div className="relative flex flex-col items-center justify-center h-full z-10">
                <Lottie options={defaultOptions}
                    height={400}
                    width={400}
                //   isStopped={this.state.isStopped}
                //   isPaused={this.state.isPaused}
                />
                <h1 className="text-white text-center text-[30px] sm:text-[40px] md:text-[100px] mt-4 font-bold ">AltGen Protocol</h1>

                <div className='flex gap-6 mt-5'>
                    <Button title="Open Bot" />
                    <Button title="Join Community" />
                </div>
            </div>
        </div>
    );
};

export default Hero;
