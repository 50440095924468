import React from 'react';
import './button.css'

const Button = (props) => {
    const { title } = props
    return (
        <div>
            <div className='flex items-center justify-center cursor-pointer button-shadow bg-[#174243] px-4 py-3 text-[16px]  md:text-[20px] text-white border-2 border-[#2A7E89]'>
                {title}
            </div>
        </div>
    )
}

export default Button
