import React from 'react'
import Button from '../Button/Button'
import Lottie from 'react-lottie';
import * as animationData from '../../Assets/l0.json'
const About = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <div>
            <section class="text-gray-600 bg-[#021F29] body-font">
                <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                    {/* <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                        <img class="object-cover object-center rounded" alt="hero" src="https://dummyimage.com/720x600" />
                    </div>
                     */}
                    <div>
                        <Lottie options={defaultOptions}
                            className='w-[1000px] mi'
                        //   isStopped={this.state.isStopped}
                        //   isPaused={this.state.isPaused}
                        />
                    </div>
                    <div class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                        <h1 class="title-font  mb-4 font-medium text-[#2A7E89] text-[40px] md:text-[70px]">ABOUT ALTS
                            {/* <br class="hidden lg:inline-block" />readymade gluten */}
                        </h1>
                        <div className='relative mb-8'>
                            {/* <img src={require('../../Assets/about_alt.png')} className='w-[1000px] mi' alt="line" /> */}
                            <p className='text-[20px] md:text-[24px] text-white left-4'>
                                The Next GEN BOT, FOr Mining Accounts, then need a story to fill with which we did not got any of it so we just filling with random data, The Next GEN BOT, FOr Mining Accounts, then need a story to fill with which we did not got any of it so we just filling with random dataThe Next GEN BOT, FOr Mining Accounts, then need a story to fill with which we did not got any of it so we just filling with random data

                            </p>
                        </div>
                        <div class="flex justify-center gap-6">
                            <Button title="Buy Now" />
                            <Button title="Whitepaper" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default About