import React from 'react'

const Timeline = () => {
    return (
        <div>
            <section class="text-gray-600 bg-black body-font pb-20 ">
                <h1 class="title-font  mb-4 font-medium text-[#2A7E89] py-24 text-center text-[40px] md:text-[70px]">
                    ROADMAP
                </h1>
                <div class="container px-5  mx-auto flex flex-wrap">

                    <div class="flex relative pt-10 pb-20 sm:items-center md:w-2/3 mx-auto">
                        <div class="h-full w-6 absolute inset-0 flex items-center justify-center">
                            <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                        </div>
                        <div class="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-[#2A7E89] text-white relative z-10 title-font font-medium text-sm">1</div>
                        <div class="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
                            <div class="flex-shrink-0 w-24 h-24  text-[#2A7E89] rounded-full inline-flex items-center justify-center">
                                <img src={require('../../Assets/ph1.png')} className='circle-spin-1'/>
                            </div>
                            <div class="flex-grow sm:pl-6 mt-6 sm:mt-0">
                                <h2 class="font-medium title-font text-[#2A7E89] mb-1 text-[32px]">BETA LAUNCH</h2>
                                <p class="leading-relaxed text-white text-[20px]">
                                To kickstart the initial launch of [ALT PROTOCOL], we will be enabling FREE Telegram account creations weekly. Users will be allowed up to 5 accounts per week at 0 cost. The soft launch beta intends to limit excessive account abuse and overload on our servers. The beta will run in tandem with a KOL campaign to support the initial beta.                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="flex relative pb-20 sm:items-center md:w-2/3 mx-auto">
                        <div class="h-full w-6 absolute inset-0 flex items-center justify-center">
                            <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                        </div>
                        <div class="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-[#2A7E89] text-white relative z-10 title-font font-medium text-sm">2</div>
                        <div class="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
                            <div class="flex-shrink-0 w-24 h-24  text-[#2A7E89] rounded-full inline-flex items-center justify-center">
                                <img src={require('../../Assets/ph2.png')} className='circle-spin-2'/>
                            </div>
                            <div class="flex-grow sm:pl-6 mt-6 sm:mt-0">
                                <h2 class="font-medium title-font text-[#2A7E89] mb-1 text-[32px]">TOKEN GATED DAPP & BURN</h2>
                                <p class="leading-relaxed text-white text-[20px]"> 
                                Users will be required to be verified to continue using [ALT PROTOCOL] by holding a fixed % of $ALTBOT which will be verifiable on a new dAPP. The % of Supply required will be adjusted for each milestone marketcap beginning at $100k, $250k, $1M, and so forth onwards. A usage fee will be implemented that burns a fixed amount of tokens for each ALT Generation.                                    </p>
                            </div>
                        </div>
                    </div>
                    <div class="flex relative pb-20 sm:items-center md:w-2/3 mx-auto">
                        <div class="h-full w-6 absolute inset-0 flex items-center justify-center">
                            <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                        </div>
                        <div class="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-[#2A7E89] text-white relative z-10 title-font font-medium text-sm">3</div>
                        <div class="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
                            <div class="flex-shrink-0 w-24 h-24  text-[#2A7E89] rounded-full inline-flex items-center justify-center">
                                <img src={require('../../Assets/ph3.png')} className='circle-spin-3' />
                            </div>
                            <div class="flex-grow sm:pl-6 mt-6 sm:mt-0">
                                <h2 class="font-medium title-font text-[#2A7E89] mb-1 text-[32px]">STAKING & COMMUNITY GROWTH</h2>
                                <p class="leading-relaxed text-white text-[20px]">
                                dAPP upgrade will enable users the ability to stake to receive a % of revenues from the usage fee. Growth will be maximized via T2 Exchanges and Community initiatives such as (Twitter Contests & Raids, Daily ETH Trending, etc). New T1 KOLs will lead this push to greater highs.                                    </p>
                            </div>
                        </div>
                    </div>
                    <div class="flex relative pb-10 sm:items-center md:w-2/3 mx-auto">
                        <div class="h-full w-6 absolute inset-0 flex items-center justify-center">
                            <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
                        </div>
                        <div class="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-[#2A7E89] text-white relative z-10 title-font font-medium text-sm">4</div>
                        <div class="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
                        <div class="flex-shrink-0 w-24 h-24  text-[#2A7E89] rounded-full inline-flex items-center justify-center">
                                <img src={require('../../Assets/ph4.png')} className='circle-spin-4'/>
                            </div>
                            <div class="flex-grow sm:pl-6 mt-6 sm:mt-0">
                                <h2 class="font-medium title-font text-[#2A7E89] mb-1 text-[32px]">MULTI PLATFORM EXPANSION</h2>
                                <p class="leading-relaxed text-white text-[20px]">
                                [ALT PROTOCOL] will open up # verification for major platforms such as Discord, Google, and TikTok. A community voting system will be implemented to allow all holders of $ALTBOT a chance to have their say on which platform will be included next.                                    </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Timeline